import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css"; 

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [thumbnail, setThumbnail] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFileName(selectedFile.name);

    if (selectedFile.type.startsWith("image/") || selectedFile.type.startsWith("video/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setThumbnail(e.target.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setThumbnail(null);
    }
  };

  useEffect(() => {
    if (thumbnail) {
      const img = new Image();
      img.src = thumbnail;
      img.onload = () => {
        if (img.width > window.innerWidth || img.height > window.innerHeight) {
          const scaleFactor = Math.min(window.innerWidth / img.width, window.innerHeight / img.height);
          img.width *= scaleFactor;
          img.height *= scaleFactor;
          setThumbnail(img.src);
        }
      };
    }
  }, [thumbnail]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      setError("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("folder_path", "userReport");
    formData.append("file", file);

    setLoading(true);

    try {
      const response = await axios.post("https://cdn-service.teleskop.app/api/v1/cdn/upload", formData);      
      setResponse(response.data);
      setError(null);
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("An error occurred while uploading the file.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="file-upload-container">
      <h2>File Upload</h2>
      <form onSubmit={handleSubmit}>
      <div className="file-input-wrapper">
          <input
            type="file"
            id="file-input"
            className="file-input"
            onChange={handleFileChange}
          />
          <hr />
          <br />
          <span className="selected-file-name">{selectedFileName}</span>{" "}
          <br />
          <br />
          <label htmlFor="file-input" className="custom-file-upload">
            Select File
          </label>
          <br />
          <br />
          <hr />
        </div>
        <button type="submit" className="upload-btn">Upload</button>
        <br/>
      </form>
      <br/>
      {response && !error && (
        <div className="response-container">
          {response.data}
        </div>
      )}
      <br/>
      {loading && <p>Loading...</p>}
      {error && <p className="error">Error: {error}</p>}
      {thumbnail && (
        <div className="thumbnail-container">
          {file.type.startsWith("image/") ? (
            <img src={thumbnail} alt="Uploaded Image" />
          ) : (
            <video controls><source src={thumbnail} type="video/mp4" /></video>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
